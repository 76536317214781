import React from "react"
import { graphql } from "gatsby"
import Page from "gatsby-theme-nieuwbouw/src/components/Page";
import {Col, Container, Row} from "react-bootstrap"
import {IPage} from "../types";
import {StaticImage} from "gatsby-plugin-image";
import {Header} from "../components/Header";
import Prijslijst from "../gatsby-theme-nieuwbouw/components/prijslijst";

interface Props {
    data: PageQueryData
}
export default ({ data }: Props) => {
    return (
        <Page pageTitle={data.file.childMarkdownRemark.frontmatter.title} pageDescription={data.file.childMarkdownRemark.frontmatter.description}>
            <Header>
                <StaticImage
                    src={'../img/ai/Binnengaats - Kokkelhof HR.jpg'}
                    aspectRatio={3}
                    layout={'fullWidth'}
                    alt={'header'}
                    transformOptions={{
                        cropFocus: "entropy"
                    }}
                />
            </Header>
            <Container
                fluid={'lg'}
                className={'my-5'}
                as={'section'}
            >
                <section>
                    <header>
                        <h2>Beschikbaarheid</h2>
                    </header>
                    <Row className={"my-5"}>
                        <Col md={5}>
                            <StaticImage
                                src={'../img/ai/Binnengaats HR.jpg'}
                                alt={'artist impression'}
                                className={'rounded image-shadow'}
                            />
                        </Col>
                        <Col md={7}>
                            <Prijslijst/>
                        </Col>
                    </Row>

                </section>

                <section className={'my-5'}>
                    <header>
                        <h2 className={'text-center'}>
                            Situatie
                        </h2>
                    </header>
                    <div className={'d-flex justify-content-center'}>
                        <StaticImage src={'../img/situatie.png'} alt={'situatie'} />
                    </div>
                </section>
                <div dangerouslySetInnerHTML={{__html: data.file.childMarkdownRemark.html}} />
            </Container>
        </Page>
    );
}

interface PageQueryData {
    file: {
        childMarkdownRemark: IPage
    }
}

export const pageQuery = graphql`
    query {
        file(name: {eq: "beschikbaar"}) {
            childMarkdownRemark {
                ...Page
            }
        }
    }
`
