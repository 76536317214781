import React from "react"
import {Bouwnummer} from "gatsby-theme-nieuwbouw/src/graphqlTypes";
import {columns} from "./Columns";
import {Table} from "react-bootstrap";
import {graphql, useStaticQuery} from "gatsby";

export default () => {
    const data = useStaticQuery(graphql`
        query {
            allBouwnummer {
                nodes {
                    ...Bouwnummer
                }
            }
        }
    `)
    return (
        <Table responsive size="sm" variant={'light'} style={{
            maxWidth: '600px'
        }}>
            <thead>
            <tr>
                {columns.map(column =>
                    <th key={column.name}>{column.name}</th>
                )}
            </tr>
            </thead>
            <tbody>
            {data.allBouwnummer.nodes.map((bouwnummer: Bouwnummer) =>
                <tr
                    key={bouwnummer.id}
                    style={{
                        textDecoration: ["VERKOCHT", "VERKOCHT_ONDER_VOORBEHOUD", "VERKOCHT_BIJ_INSCHRIJVING"].includes(String(bouwnummer.financieel?.overdracht?.status)) ? 'line-through' : "none"
                    }}
                >
                    {columns.map(column =>
                        <td key={column.name}>
                            {column.format(bouwnummer)}
                        </td>
                    )}
                </tr>
            )}
            </tbody>
        </Table>
    )
}

