import React from "react"
import Prijs from 'gatsby-theme-nieuwbouw/src/components/Prijs'
import Oppervlakte from "gatsby-theme-nieuwbouw/src/components/Oppervlakte"
import { statusMap } from "gatsby-theme-nieuwbouw/src/utils/utils"
import {Bouwnummer} from "gatsby-theme-nieuwbouw/src/graphqlTypes";
import {graphql} from "gatsby";

export const columns = [
  {
    name: 'Bouwnr.',
    format: (bouwnummer: Bouwnummer) => bouwnummer.diversen.diversen.bouwnummer,
  },
  {
    name: 'Woonlaag',
    format: (bouwnummer: Bouwnummer) => bouwnummer.algemeen.appartementWoonlaag && bouwnummer.algemeen.appartementWoonlaag,
  },
  {
    name: 'Woonopp.',
    format: (bouwnummer: Bouwnummer) => bouwnummer.algemeen.woonoppervlakte && <Oppervlakte oppervlakte={bouwnummer.algemeen.woonoppervlakte} />,
  },
  {
    name: 'Prijs V.O.N.',
    format: (bouwnummer: Bouwnummer) => (bouwnummer.financieel.overdracht.status === 'BESCHIKBAAR' && bouwnummer.financieel.overdracht.koopprijs) && <Prijs prijs={bouwnummer.financieel.overdracht.koopprijs} />,
  },
  {
    name: 'Status',
    format: (bouwnummer: Bouwnummer) => bouwnummer.financieel.overdracht.status && statusMap[bouwnummer.financieel.overdracht.status]['naam'],
  },
];

export const query = graphql`
  fragment Bouwnummer on Bouwnummer {
    id
    algemeen {
      woonoppervlakte
      appartementWoonlaag
    }
    financieel {
      overdracht {
        koopprijs
        status
      }
    }
    diversen {
      diversen {
        bouwnummer
      }
    }
  }
`
